var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "mb-3" }),
      _c(
        "div",
        { staticClass: "sub-header" },
        [
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.selection.length > 0,
                variant: "outline-primary",
                "aria-label": "Cancel Selected Order(s)",
              },
              on: {
                click: function ($event) {
                  return _vm.showCancelModal()
                },
              },
            },
            [_vm._v("\n      Cancel Selected\n    ")]
          ),
        ],
        1
      ),
      _c("ajax-table", {
        ref: "vehicleFilingsTable",
        attrs: {
          busy: _vm.isBusy,
          "table-definition": _vm.tableDefinition,
          "select-mode": "multi",
          selectable: true,
          stickyHeader: false,
          responsive: false,
          emptyText: "You're all caught up!",
          "selectable-function": _vm.canCancel,
        },
        on: {
          "update:busy": function ($event) {
            _vm.isBusy = $event
          },
          "selection-changed": _vm.selectionEvent,
          loaded: _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(filing_name)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "text-capitalize" }, [
                  _vm._v(_vm._s(data.item.product.filing_name)),
                ]),
              ]
            },
          },
          {
            key: "cell(year)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.data.vehicle_year))])]
            },
          },
          {
            key: "cell(make)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.data.vehicle_make))])]
            },
          },
          {
            key: "cell(model)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.data.vehicle_model))])]
            },
          },
          {
            key: "cell(vin)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(data.item.data.vin___hin)),
                ]),
              ]
            },
          },
          {
            key: "cell(company)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.company.name))])]
            },
          },
          {
            key: "row-details",
            fn: function (data) {
              return [
                _vm.vehicleStatuses.includes(data.item.status)
                  ? _c("vehicle-tracker", {
                      attrs: {
                        status: data.item.status,
                        "filing-name": data.item.product.filing_name,
                        estimate: _vm.formatEstimate(data.item),
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(status)",
            fn: function (data) {
              return [
                _vm.vehicleStatuses.includes(data.item.status)
                  ? _c(
                      "b-button",
                      {
                        staticClass: "toggle-tracker text-capitalize",
                        attrs: {
                          variant: "link",
                          "aria-label": "toggle tracker",
                        },
                        on: { click: data.toggleDetails },
                      },
                      [
                        _vm._v("\n        Tracker\n        "),
                        data.detailsShowing
                          ? _c("b-icon-chevron-up")
                          : _c("b-icon-chevron-down"),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.readableStatus(data.item.status)) +
                          "\n      "
                      ),
                    ]),
              ]
            },
          },
        ]),
      }),
      _c("cancel-filings-modal", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.filingsCancelCompleted },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("Vehicle Orders")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }